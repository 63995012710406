<template>
  <div class="text-2xl font-bold text-invest-primary">Create an Investment</div>
  <p class="mt-3 text-xs text-invest-dark">Enter a name for your investment.</p>
  <div class="mt-8">
    <form @submit.prevent="navigate">
      <form-input
        name="investment_name"
        :form="investment"
        v-model="investment.data.investment_name.value"
      >
        Investment name
      </form-input>

      <!-- <form-currency v-model="investment.data.investment_name.value" /> -->

      <div class="grid grid-cols-1 h-72 place-content-end">
        <button type="submit" class="btn btn-primary">Continue</button>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, SetupContext, toRefs } from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';

export default defineComponent({
  inheritAttrs: false,
  emits: ['navigateToSelectTenure'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm } = resource();
    const { validateForm } = validation();
    const data = reactive({
      investment: basicForm([
        {
          name: 'investment_name',
          rules: 'required',
        },
      ]),
      currency: '',
    });

    const navigate = () => {
      if (!validateForm(data.investment)) return false;
      emit(
        'navigateToSelectTenure',
        data.investment.data.investment_name.value,
      );
    };

    return { ...toRefs(data), navigate };
  },
});
</script>
