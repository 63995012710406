
import { defineComponent, reactive, SetupContext, toRefs } from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';

export default defineComponent({
  inheritAttrs: false,
  emits: ['navigateToSelectTenure'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm } = resource();
    const { validateForm } = validation();
    const data = reactive({
      investment: basicForm([
        {
          name: 'investment_name',
          rules: 'required',
        },
      ]),
      currency: '',
    });

    const navigate = () => {
      if (!validateForm(data.investment)) return false;
      emit(
        'navigateToSelectTenure',
        data.investment.data.investment_name.value,
      );
    };

    return { ...toRefs(data), navigate };
  },
});
